import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'rgba(51, 51, 51, 0.5)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <ScrollLink
          to="hero"
          smooth={true}
          duration={500}
          style={{ textDecoration: 'none', flexGrow: 1 }}
        >
          <Typography variant="h6" sx={{ color: '#fff', cursor: 'pointer' }}>
            Japascripts
          </Typography>
        </ScrollLink>

        <ScrollLink
          to="services"
          smooth={true}
          duration={500}
          style={{ textDecoration: 'none' }}
        >
          <Button
            color="inherit"
            sx={{
              color: 'rgba(255, 255, 255, 0.8)',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                backdropFilter: 'blur(5px)',
              },
            }}
          >
            Serviços
          </Button>
        </ScrollLink>
        <ScrollLink
          to="about"
          smooth={true}
          duration={500}
          style={{ textDecoration: 'none' }}
        >
          <Button
            color="inherit"
            sx={{
              color: 'rgba(255, 255, 255, 0.8)',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                backdropFilter: 'blur(5px)',
              },
            }}
          >
            Sobre
          </Button>
        </ScrollLink>
        <ScrollLink
          to="contact"
          smooth={true}
          duration={500}
          style={{ textDecoration: 'none' }}
        >
          <Button
            color="inherit"
            sx={{
              color: 'rgba(255, 255, 255, 0.8)',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                backdropFilter: 'blur(5px)',
              },
            }}
          >
            Contato
          </Button>
        </ScrollLink>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
