import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ApplicationCard = ({ title, description, logo, route }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '15px',
        maxWidth: '600px',
        backgroundColor: '#f9f9f9'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '8px' }}>
          {description}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleButtonClick}
          sx={{ marginTop: '16px' }}
        >
          Saiba Mais
        </Button>
      </Box>
      <Box 
        component="img" 
        src={logo} 
        alt={`${title} logo`}
        sx={{
          width: '80px',
          height: '80px',
          objectFit: 'contain',
          marginLeft: '16px',
        }}
      />
    </Box>
  );
};

export default ApplicationCard;
