import React from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const ServiceCard = ({ title, description }) => (
  <Box
    sx={{
      backgroundColor: '#f5f5f5',
      padding: 4,
      borderRadius: 2,
      boxShadow: 3,
      textAlign: 'center',
    }}
  >
    <motion.div whileHover={{ scale: 1.05 }}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </motion.div>
  </Box>
);

const Services = () => {
  return (
    <Container id="services" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" textAlign="center" gutterBottom>
          Nossos Serviços
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ServiceCard
              title="Landing Page"
              description="Fazemos a criação de landing pages para o seu negócio."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              title="Desenvolvimento"
              description="Desenvolvemos aplicações personalizadas."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              title="Suporte"
              description="Atendimento e suporte 24 horas."
            />
          </Grid>
        </Grid>
      </motion.div>
    </Container>
  );
};

export default Services;
