import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Drawer, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import suaPousadaLogo from '../../assets/suaPousadaLogo.png';

const SuaPousadaHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          boxShadow: 'none',
          borderBottom: '1px solid #e0e0e0',
          backdropFilter: 'blur(10px)',
          padding: '0 20px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <RouterLink to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <img
              src={suaPousadaLogo}
              alt="Logo Sua Pousada"
              style={{ width: '40px', height: '40px', marginRight: '10px' }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                fontSize: '24px',
                color: '#333',
                textDecoration: 'none',
              }}
            >
              Sua Pousada
            </Typography>
          </RouterLink>

          <IconButton onClick={toggleDrawer(true)} sx={{ color: '#333' }}>
            <MenuIcon />
          </IconButton>

          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 250 }}>
              <Typography variant="h6" sx={{ padding: '16px', textDecoration: 'none' }}>
                Menu
              </Typography>
              <ScrollLink
                to="features"
                smooth={true}
                duration={500}
                onClick={toggleDrawer(false)}
                style={{
                  width: '100%',
                  display: 'block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  color: '#555',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
                activeClass="active"
              >
                Funcionalidades
              </ScrollLink>
              <ScrollLink
                to="pricingTable"
                smooth={true}
                duration={500}
                onClick={toggleDrawer(false)}
                style={{
                  width: '100%',
                  display: 'block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  color: '#555',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
                activeClass="active"
              >
                Tabela de Planos
              </ScrollLink>
              <ScrollLink
                to="testimonials"
                smooth={true}
                duration={500}
                onClick={toggleDrawer(false)}
                style={{
                  width: '100%',
                  display: 'block',
                  textAlign: 'left',
                  textDecoration: 'none',
                  color: '#555',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
                activeClass="active"
              >
                Depoimentos
              </ScrollLink>
              {/* Adicione outros links de navegação aqui */}
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SuaPousadaHeader;