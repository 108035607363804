// components/HeroSection.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import HeroSuaPousada from '../../assets/HeroSuaPousada.jpg';

const HeroSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: `url(${HeroSuaPousada}) no-repeat center center/cover`,
        color: '#fff',
        textAlign: 'center',
        padding: '0 20px',
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        Bem-vindo à Sua Pousada
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: '300', maxWidth: '600px', marginBottom: '30px' }}>
        Simplifique a gestão da sua pousada com uma plataforma intuitiva e completa para todas as suas necessidades.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          padding: '10px 30px',
          fontSize: '18px',
          borderRadius: '25px',
          textTransform: 'none',
          "&:hover": {
            backgroundColor: '#1e88e5',
          },
        }}
        href="#features" // Pode ser alterado para direcionar para a seção de cadastro ou outra página
      >
        Comece Agora
      </Button>
    </Box>
  );
};

export default HeroSection;
