import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <Container id="about" sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" textAlign="center" gutterBottom>
          Sobre Nós
        </Typography>
        <Box sx={{ textAlign: 'center', maxWidth: '800px', margin: 'auto' }}>
          <Typography variant="body1" paragraph>
          Somos uma pequena equipe apaixonada por melhorar sistemas que muitas vezes carecem de funcionalidades essenciais e sofrem com falhas na experiência visual.
          </Typography>
          <Typography variant="body1" paragraph>
            Nosso foco é entender as necessidades do seu negócio e transformar desafios em oportunidades de crescimento, por meio de tecnologias modernas e um suporte ágil e eficiente.
          </Typography>
          <Typography variant="body1" paragraph>
            Se você procura uma parceria confiável, estamos prontos para ajudar a sua empresa a alcançar novos patamares.
          </Typography>
        </Box>
      </motion.div>
    </Container>
  );
};

export default About;
