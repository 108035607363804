// pages/HomePage.js
import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import ApplicationsList from '../components/ApplicationsList';
import About from '../components/About';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <Box>
      <Header />
      <HeroSection />
      <Services />
      <ApplicationsList />
      <About />
      <ContactForm />
      <Footer />
    </Box>
  );
};

export default HomePage;
