// pages/SuaPousadaPage.js
import React from 'react';
import { Box } from '@mui/material';
import SuaPousadaHeader from '../components/SuaPousada/SuaPousadaHeader';
import HeroSection from '../components/SuaPousada/HeroSection';
import FeaturesSection from '../components/SuaPousada/FeaturesSection';
import PricingTable from '../components/SuaPousada/PricingTable';
import Testimonials from '../components/SuaPousada/Testmonials';
import Footer from '../components/Footer';

const SuaPousadaPage = () => {
  return (
    <Box>
      <SuaPousadaHeader />
      <HeroSection />
      <h2>Em Desenvolvimento</h2>
      <Footer />
    </Box>
  );
};

export default SuaPousadaPage;
