import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SuaPousadaPage from './pages/SuaPousadaPage';
import theme from './styles/theme';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/suapousada" element={<SuaPousadaPage />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;


