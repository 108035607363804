import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import suaLogo from '../assets/suaLogo.png';

const HeroSection = () => {
  return (
    <Box
      id="hero"
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${suaLogo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        color: '#fff',
      }}
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h2" gutterBottom>
            Bem-vindo à Japascripts
          </Typography>
          <Typography variant="h6" gutterBottom>
            Soluções digitais para o seu negócio
          </Typography>
          <ScrollLink to="services" smooth={true} duration={1000}>
            <Button 
              sx={{
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: 'rgba(186, 190, 190, 0.8)',
                  backgroundColor: 'rgba(130, 132, 140, 0.2)',
                  backdropFilter: 'blur(5px)',
                },
              }}
              variant="contained" color="inherit" size="large" component="a">
              Saiba Mais
            </Button>
          </ScrollLink>
        </motion.div>
      </Container>
    </Box>
  );
};

export default HeroSection;
