import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#333', color: '#fff', textAlign: 'center' }}>
      <Typography variant="body2">
        &copy; 2024 Japascripts. Todos os direitos reservados.
      </Typography>
    </Box>
  );
};

export default Footer;
