import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = 'Nome é obrigatório';
    if (!formData.email) formErrors.email = 'Email é obrigatório';
    if (!formData.message) formErrors.message = 'Mensagem é obrigatória';
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
  
      const emailParams = {
        user_name: formData.name,
        user_email: formData.email,
        message: formData.message,
      };
  
      emailjs.send('service_9vn4q4w', 'template_timjqc4', emailParams, 'HPDEKRTNW1ZXhuXHz')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccessMessage('Mensagem enviada com sucesso!');
          setErrorMessage('');
          setIsSubmitting(false);
          setFormData({ name: '', email: '', message: '' });
        }, (err) => {
          console.log('FAILED...', err);
          setErrorMessage('Falha ao enviar a mensagem.');
          setIsSubmitting(false);
        });
    } else {
      setErrors(validationErrors);
      setSuccessMessage('');
    }
  };
  

  return (
    <Container id="contact" sx={{ py: 8 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Entre em Contato
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: '600px', margin: 'auto' }}
      >
        <TextField
          fullWidth
          label="Nome"
          variant="outlined"
          margin="normal"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={Boolean(errors.name)}
          helperText={errors.name}
          required
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          required
        />
        <TextField
          fullWidth
          label="Mensagem"
          variant="outlined"
          margin="normal"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={4}
          error={Boolean(errors.message)}
          helperText={errors.message}
          required
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </Button>
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default ContactForm;